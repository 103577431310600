<template>
  <div class="PrivacyPolicyView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>
          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.summary.subtitle"/>
            <TextBlockDefault :textContent="messages.summary.content1"/>
            <TextBlockDefault :textContent="messages.summary.content2"/>
            <TextBlockDefault :textContent="messages.summary.content3"/>
            <TextBlockDefault :textContent="messages.summary.content4"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.about.subtitle"/>
            <TextBlockDefault :textContent="messages.about.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.what.subtitle"/>
            <TextBlockDefault :textContent="messages.what.content1"/>
            <md-list>
              <md-list-item>{{ messages.what.listItem1 }}</md-list-item>
              <md-list-item>{{ messages.what.listItem2 }}</md-list-item>
              <md-list-item>{{ messages.what.listItem3 }}</md-list-item>
            </md-list>
            <TextBlockDefault :textContent="messages.what.content2"/>
            <md-list>
              <md-list-item>{{ messages.what.listItem4 }}</md-list-item>
              <md-list-item>{{ messages.what.listItem5 }}</md-list-item>
            </md-list>
            <TextBlockDefault :textContent="messages.what.content3"/>
            <TextBlockDefault :textContent="messages.what.content4"/>
            <TextBlockDefault :textContent="messages.what.content5"/>
            <TextBlockDefault :textContent="messages.what.content6"/>
            <TextBlockDefault :textContent="messages.what.content7"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.how.subtitle"/>
            <TextBlockDefault :textContent="messages.how.content1"/>
            <TextBlockDefault :textContent="messages.how.content2"/>
            <md-list>
              <md-list-item>{{ messages.how.listItem1 }}</md-list-item>
              <md-list-item>{{ messages.how.listItem2 }}</md-list-item>
              <md-list-item>{{ messages.how.listItem3 }}</md-list-item>
              <md-list-item>{{ messages.how.listItem4 }}</md-list-item>
              <md-list-item>{{ messages.how.listItem5 }}</md-list-item>
            </md-list>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.thirdParties.subtitle"/>
            <TextBlockDefault :textContent="messages.thirdParties.content1"/>
            <TextBlockDefault :textContent="messages.thirdParties.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.communication.subtitle"/>
            <TextBlockDefault :textContent="messages.communication.content1"/>
            <TextBlockDefault :textContent="messages.communication.content2"/>
            <TextBlockDefault :textContent="messages.communication.content3"/>
            <TextBlockDefault :textContent="messages.communication.content4"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.transfer.subtitle"/>
            <TextBlockDefault :textContent="messages.transfer.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.regulatory.subtitle"/>
            <TextBlockDefault :textContent="messages.regulatory.content1"/>
            <TextBlockDefault :textContent="messages.regulatory.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.thirdPartyWebsites.subtitle"/>
            <TextBlockDefault :textContent="messages.thirdPartyWebsites.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.duration.subtitle"/>
            <TextBlockDefault :textContent="messages.duration.content1"/>
            <TextBlockDefault :textContent="messages.duration.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.age.subtitle"/>
            <TextBlockDefault :textContent="messages.age.content1"/>
            <TextBlockDefault :textContent="messages.age.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.rights.subtitle"/>
            <TextBlockDefault :textContent="messages.rights.content1"/>
            <md-list>
              <md-list-item>{{ messages.rights.listItem1 }}</md-list-item>
              <md-list-item>{{ messages.rights.listItem2 }}</md-list-item>
              <md-list-item>{{ messages.rights.listItem3 }}</md-list-item>
              <md-list-item>{{ messages.rights.listItem4 }}</md-list-item>
              <md-list-item>{{ messages.rights.listItem5 }}</md-list-item>
              <md-list-item>{{ messages.rights.listItem6 }}</md-list-item>
              <md-list-item>{{ messages.rights.listItem7 }}</md-list-item>
            </md-list>
            <TextBlockDefault :textContent="messages.rights.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.changes.subtitle"/>
            <TextBlockDefault :textContent="messages.changes.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.contact.subtitle"/>
            <TextBlockDefault :textContent="messages.contact.content1"/>
            <md-list>
              <md-list-item>{{ messages.contact.listItem1 }}</md-list-item>
              <md-list-item>{{ messages.contact.listItem2 }}</md-list-item>
              <md-list-item>{{ messages.contact.listItem3 }}</md-list-item>
            </md-list>
            <SectionSubtitle :titleContent="messages.contact.content2"/>
          </div>

          <div class="o-container-subsection">
            <TextBlockDefault :textContent="messages.copyright.content1"/>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'PrivacyPolicyView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      messages: {
        title: this.$t('privacyPolicyView.title'),
        summary: {
          subtitle: this.$t('privacyPolicyView.summary.subtitle'),
          content1: this.$t('privacyPolicyView.summary.content1'),
          content2: this.$t('privacyPolicyView.summary.content2'),
          content3: this.$t('privacyPolicyView.summary.content3'),
          content4: this.$t('privacyPolicyView.summary.content4'),
        },
        about: {
          subtitle: this.$t('privacyPolicyView.about.subtitle'),
          content1: this.$t('privacyPolicyView.about.content1')
        },
        what: {
          subtitle: this.$t('privacyPolicyView.what.subtitle'),
          content1: this.$t('privacyPolicyView.what.content1'),
          listItem1: this.$t('privacyPolicyView.what.listItem1'),
          listItem2: this.$t('privacyPolicyView.what.listItem2'),
          listItem3: this.$t('privacyPolicyView.what.listItem3'),
          content2: this.$t('privacyPolicyView.what.content2'),
          listItem4: this.$t('privacyPolicyView.what.listItem4'),
          listItem5: this.$t('privacyPolicyView.what.listItem5'),
          content3: this.$t('privacyPolicyView.what.content3'),
          content4: this.$t('privacyPolicyView.what.content4'),
          content5: this.$t('privacyPolicyView.what.content5'),
          content6: this.$t('privacyPolicyView.what.content6'),
          content7: this.$t('privacyPolicyView.what.content7'),
        },
        how: {
          subtitle: this.$t('privacyPolicyView.how.subtitle'),
          content1: this.$t('privacyPolicyView.how.content1'),
          content2: this.$t('privacyPolicyView.how.content2'),
          listItem1: this.$t('privacyPolicyView.how.listItem1'),
          listItem2: this.$t('privacyPolicyView.how.listItem2'),
          listItem3: this.$t('privacyPolicyView.how.listItem3'),
          listItem4: this.$t('privacyPolicyView.how.listItem4'),
          listItem5: this.$t('privacyPolicyView.how.listItem5'),
        },
        thirdParties: {
          subtitle: this.$t('privacyPolicyView.thirdParties.subtitle'),
          content1: this.$t('privacyPolicyView.thirdParties.content1'),
          content2: this.$t('privacyPolicyView.thirdParties.content2'),
        },
        communication: {
          subtitle: this.$t('privacyPolicyView.communication.subtitle'),
          content1: this.$t('privacyPolicyView.communication.content1'),
          content2: this.$t('privacyPolicyView.communication.content2'),
          content3: this.$t('privacyPolicyView.communication.content3'),
          content4: this.$t('privacyPolicyView.communication.content4'),
        },
        transfer: {
          subtitle: this.$t('privacyPolicyView.transfer.subtitle'),
          content1: this.$t('privacyPolicyView.transfer.content1')
        },
        regulatory: {
          subtitle: this.$t('privacyPolicyView.regulatory.subtitle'),
          content1: this.$t('privacyPolicyView.regulatory.content1'),
          content2: this.$t('privacyPolicyView.regulatory.content2')
        },
        thirdPartyWebsites: {
          subtitle: this.$t('privacyPolicyView.thirdPartyWebsites.subtitle'),
          content1: this.$t('privacyPolicyView.thirdPartyWebsites.content1'),
        },
        duration: {
          subtitle: this.$t('privacyPolicyView.duration.subtitle'),
          content1: this.$t('privacyPolicyView.duration.content1'),
          content2: this.$t('privacyPolicyView.duration.content2'),
        },
        age: {
          subtitle: this.$t('privacyPolicyView.age.subtitle'),
          content1: this.$t('privacyPolicyView.age.content1'),
        },
        rights: {
          subtitle: this.$t('privacyPolicyView.rights.subtitle'),
          content1: this.$t('privacyPolicyView.rights.content1'),
          listItem1: this.$t('privacyPolicyView.rights.listItem1'),
          listItem2: this.$t('privacyPolicyView.rights.listItem2'),
          listItem3: this.$t('privacyPolicyView.rights.listItem3'),
          listItem4: this.$t('privacyPolicyView.rights.listItem4'),
          listItem5: this.$t('privacyPolicyView.rights.listItem5'),
          listItem6: this.$t('privacyPolicyView.rights.listItem6'),
          listItem7: this.$t('privacyPolicyView.rights.listItem7'),
          content2: this.$t('privacyPolicyView.rights.content2'),
        },
        changes: {
          subtitle: this.$t('privacyPolicyView.changes.subtitle'),
          content1: this.$t('privacyPolicyView.changes.content1'),
        },
        contact: {
          subtitle: this.$t('privacyPolicyView.contact.subtitle'),
          content1: this.$t('privacyPolicyView.contact.content1'),
          listItem1: this.$t('privacyPolicyView.contact.listItem1'),
          listItem2: this.$t('privacyPolicyView.contact.listItem2'),
          listItem3: this.$t('privacyPolicyView.contact.listItem3'),
          content2: this.$t('privacyPolicyView.contact.content2'),
        },
        copyright: {
          content1: this.$t('privacyPolicyView.copyright.content1')
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.PrivacyPolicyView {

  .md-list {
    margin: 1.6rem .8rem;
  }

  .o-default-body {
    white-space: pre-wrap;
  }

  .strong {
    text-align: left;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }
}
</style>
